
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';
import { Home } from './pages/home/home';
import { Layout } from './layout/Layout';
import { AppRoute, CustomRoutes } from './routes'

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'


function MyRoutes() {

  return (
    < >
      <ReactNotifications />

      <BrowserRouter>
        <Routes>

          <Route path="/" element={<Layout />}>

            {/* Default index page */}
            <Route index element={<Home />} />

            {/* These get loaded from the routes. */}
            {CustomRoutes.map((cRo: AppRoute) => {

              return (
                <Route
                  key={cRo.DisplayName}
                  path={cRo.Navigation}
                  element={cRo.Protected ?
                    
                      <cRo.Component />
                     :
                    <cRo.Component />}
                />
              )
            })}
           
            {/* Catches any routes not selected */}
            <Route path='*' element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

function App() {
  return (
      <MyRoutes />
  );
}

export default App;