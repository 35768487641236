
import { Outlet } from 'react-router-dom';

export function Layout() {
  return (
    <>
      <div style={{minHeight: '90vh'}}>
        <div style={{ marginTop: '12vh', minHeight: '70vh' }}>
          <Outlet />
        </div>
      </div>
    </>
  );
}