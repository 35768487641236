
import { Home } from "./pages/home/home"

export interface AppRoute{
    Navigation: string,
    DisplayName: string,
    Protected: boolean,
    Component: () => JSX.Element,
    NavBarVisibile: boolean
}

export const CustomRoutes : AppRoute[] = [
    {
        DisplayName: 'Home',
        Navigation: '/home',
        Protected: false,
        Component: Home,
        NavBarVisibile: true
    }
    
]