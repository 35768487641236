
//ICONS
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Grid, Typography } from '@mui/material';

//CSS
import './home.css';

//IMAGES
import Pyrrho from "../../assets/images/pyrro-logo-black.png";

export function Home() {

  return (
    <>
      <Grid container sx={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center" }}>
        <EngineeringIcon sx={{ width: '10vw', height: '10vw', marginBottom: "1vh" }}></EngineeringIcon>
        <Typography gutterBottom variant="h3" sx={{ fontFamily: "Roboto" }}>Sorry, this website is still under construction</Typography>
        <Typography variant="h6" sx={{ fontFamily: "Poppins", display: "flex", flexFlow: "row", alignItems: "baseline" }}>Currently being created by  <a href='https://www.pyrrhotech.com/'><img src={Pyrrho} /> Pyrrho Tech</a></Typography>
      </Grid>
    </>
  );
}
